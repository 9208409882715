import {
    Button,
    Row,
    Col,
    Input,
    Form,
    Tag,
    Space,
    Radio,
    message,
} from "antd";
import React, { useState } from "react";
import "./index.css";
import { register } from "../../services/User/api";
import { useNavigate } from "react-router-dom";

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await register({ ...values, role });
            if (response?.status) {
                message.success(response.message);
                setLoading(false);
                navigate("/login");
            }
            if (response?.errors.email) {
                message.warning(response.errors.email[0]);
                setLoading(false);
            }
        } catch (err) {
        }
    };

    return (
        <Row className="register-row">
            <Col className="login-title" xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }}>
                <img alt="" src="/assets/icons/logo.svg" />
            </Col>
            <Col
                xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }}
                style={{ paddingBottom: "20px" }}
            >
                <Form name="register" onFinish={onSubmit}>
                    メールアドレス{" "}
                    <Tag color="#DC4602" style={{ borderRadius: "4px" }}>
                        必須
                    </Tag>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "メールアドレスを入力してください。",
                            },
                        ]}
                    >
                        <Input
                            className="register-input"
                            placeholder="glucose@flight.com"
                            min={8}
                            type="email"
                        />
                    </Form.Item>
                    パスワード{" "}
                    <Tag color="#DC4602" style={{ borderRadius: "4px" }}>
                        必須
                    </Tag>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "パスワードを入力してください。",
                            },
                            { min: 5, message: "パスワードは５文字以上で設定してください。." },
                        ]}
                    >
                        <Input.Password
                            className="register-input"
                            placeholder="半角英数８文字以上"
                            iconRender={
                                visible => (visible ?
                                    <img alt='eye' src='/assets/icons/eye.svg' />
                                    :
                                    <img alt='eye_off' src='/assets/icons/eye_off.svg' />
                                )
                            }
                        />
                    </Form.Item>
                    パスワード再入力{" "}
                    <Tag color="#DC4602" style={{ borderRadius: "4px" }}>
                        必須
                    </Tag>
                    <Form.Item
                        name="confirm"
                        rules={[
                            {
                                required: true,
                                message: "パスワードを確認してください。",
                            },
                            { min: 5, message: "パスワードは５文字以上で設定してください。." },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            "パスワードと確認用パスワードが一致しません"
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            className="register-input"
                            placeholder="半角英数８文字以上"
                            iconRender={
                                visible => (visible ?
                                    <img alt='eye' src='/assets/icons/eye.svg' />
                                    :
                                    <img alt='eye_off' src='/assets/icons/eye_off.svg' />
                                )
                            }
                        />
                    </Form.Item>
                    アカウント名{" "}
                    <Tag color="#DC4602" style={{ borderRadius: "4px" }}>
                        必須
                    </Tag>
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "名前を入力してください",
                            },
                        ]}
                    >
                        <Input
                            className="register-input"
                            placeholder="渋谷ぐるふらクリニック"
                        />
                    </Form.Item>
                    権限{" "}
                    <Tag color="#DC4602" style={{ borderRadius: "4px" }}>
                        必須
                    </Tag>
                    <Form.Item>
                        <Radio.Group
                            onChange={(e) => {
                                setRole(e.target.value);
                            }}
                            defaultValue={1}
                            style={{ marginTop: "10px" }}
                        >
                            <Space direction="vertical">
                                <Radio value={1}>ドクター（クリニック）</Radio>
                                <Radio value={2}>CRC</Radio>
                                <Radio value={3}>THE PHAGE</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item style={{ textAlign: "center" }}>
                        <Button
                            className="register-submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            アカウントを作成する
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <span className="footer" style={{ backgroundColor: "#FFFFFF" }}>
                <p>THE PHAGE, Inc.</p>
            </span>
        </Row>
    );
};

export default Register;
