import { Button, Col, Layout, Row } from "antd";
import Peer from "peerjs";
import { useEffect, useRef, useState } from "react";
import { SmileOutlined, AudioMutedOutlined, DesktopOutlined, InfoCircleOutlined, MessageOutlined, CloseOutlined } from '@ant-design/icons';
const { Content } = Layout;

declare const navigator: any;

const VideoCall: React.FC = () => {

    const [peerId, setPeerId] = useState('');
    const [remotePeerIdValue, setRemotePeerIdValue] = useState('');
    const remoteVideoRef = useRef<any>(null);
    const currentUserVideoRef = useRef<any>(null);
    const peerInstance = useRef<any>(null);

    useEffect(() => {
        open();
    }, [])

    const open = () => {
        const peer = new Peer({
            config: {'iceServers': [
              { url: 'stun:stun.l.google.com:19302' },
              { url: 'stun:stun.2.google.com:19302' }
            ]} /* Sample servers, please use appropriate ones */
          });

        peer.on('open', (id) => {
            setPeerId(id)
        });

        peer.on('call', (call) => {
            var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            getUserMedia({ video: true, audio: true }, (mediaStream) => {
                console.log('open aswer1')
                call.answer(mediaStream)
                call.on('stream', function (remoteStream) {
                    console.log('open stream1')
                    remoteVideoRef.current.srcObject = remoteStream
                    remoteVideoRef.current.play();
                });
            });
        })

        peerInstance.current = peer;
    }

    const call = (remotePeerId) => {
        var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        getUserMedia({ video: true, audio: true }, (mediaStream) => {

            currentUserVideoRef.current.srcObject = mediaStream;
            currentUserVideoRef.current.play();

            const call = peerInstance.current.call(remotePeerId, mediaStream)

            call.on('stream', (remoteStream) => {
                console.log('call stream2')
                remoteVideoRef.current.srcObject = remoteStream
                remoteVideoRef.current.play();
            });
        });
    }

    const share = (remotePeerId) => {
        var displayMediaOptions = {
            video: {
                cursor: "always"
            },
            audio: false
        };

        navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
            .then(function (mediaStream) {
                console.log('share')
                currentUserVideoRef.current.srcObject = mediaStream
                currentUserVideoRef.current.play();
                peerInstance.current.call(remotePeerId, mediaStream)
            });
    }

    function switchCamera() {
        navigator.mediaDevices.getUserMedia({
            video: { facingMode: { exact: "environment" } },
            audio: false
        })
            .then(function (newStream) {
                const oldStream = currentUserVideoRef.current.srcObject;
                const oldVideoTrack = currentUserVideoRef.current.srcObject.getVideoTracks()[0];
                const newVideoTrack = newStream.getVideoTracks()[0];
                currentUserVideoRef.current.srcObject.removeTrack(oldVideoTrack);
                oldStream.addTrack(newVideoTrack);
                peerInstance.current.peerConnection.getSenders()[1].replaceTrack(newVideoTrack);
                currentUserVideoRef.current.srcObject = newStream;
            })
            .catch(function (err) {
                console.log('Error switching camera: ', err);
            });
    }

    return (
        <Layout>
            <Content>
                <Row>
                    <Col span={24}>
                        <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
                            <video style={{ width: "100vw", height: "100vh", objectFit: 'cover' }} ref={remoteVideoRef} />
                            <div style={{ position: 'absolute', top: 20, right: 0, width: "15%", height: "20%" }}>
                                <video ref={currentUserVideoRef} width="100%" height="auto" />
                            </div>
                        </div>
                        <div style={{ position: 'absolute', top: '90%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Button onClick={() => switchCamera()} type="link" shape="circle" size="large" icon={<AudioMutedOutlined />} style={{ marginRight: '16px', backgroundColor: 'gray', color: 'white' }} />
                            <Button type="link" shape="circle" size="large" icon={<DesktopOutlined />} style={{ marginRight: '16px', backgroundColor: 'gray', color: 'white' }} />
                            <Button type="link" shape="circle" size="large" icon={<CloseOutlined />} style={{ marginRight: '16px', backgroundColor: 'red', color: 'white' }} />
                            <Button type="link" shape="circle" size="large" icon={<MessageOutlined />} style={{ marginRight: '16px', backgroundColor: 'gray', color: 'white' }} />
                            <Button type="link" shape="circle" size="large" icon={<InfoCircleOutlined />} style={{ backgroundColor: 'gray', color: 'white' }} />
                        </div>
                    </Col>
                </Row>
            </Content>
            <h1>Current user id is {peerId}</h1>
            <input type="text" value={remotePeerIdValue} onChange={e => setRemotePeerIdValue(e.target.value)} />
            <button onClick={() => call(remotePeerIdValue)}>Call</button>
            <button onClick={() => share(remotePeerIdValue)}>Share</button>
            <Button type="primary" shape="circle" icon={<SmileOutlined />} />
        </Layout>
    );
};
export default VideoCall;