import { Button, Row, Col, Input, Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { login } from '../../services/User/api';
import { AUTH_ACCESS_TOKEN } from '../../utils/constant';
import './index.css';
import { AppContext } from '../../App';

const Login: React.FC = () => {

    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: any) => {
        setLoading(true);
        const res = await login(values);
        if (res?.status) {
            localStorage.setItem(AUTH_ACCESS_TOKEN, res?.token);
            message.success(res?.message);
            await setIsLoggedIn(true);
            navigate('/home');
        }
        else {
            message.error(res?.message);
        }
        setLoading(false);
    }

    return (
        <Row className='login-row'>
            <Col className='login-title' xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }}>
                <img alt='' src='/assets/icons/logo.svg' />
            </Col>
            <Col xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }}>
                <Form
                    name='login'
                    onFinish={onFinish}
                >
                    <Form.Item
                        name='email'
                        rules={[
                            {
                                required: true,
                                message: "正しいID または メールアドレスを入力してください。"
                            }
                        ]}
                    >
                        <Input
                            type='email'
                            placeholder='メールアドレス'
                            prefix={<img alt='email' src='/assets/icons/email.svg' />}
                            style={{
                                padding: '10px',
                                height: 'auto',
                                borderRadius: '10px',
                                boxShadow: '0px 0px 8px #293C4F29'
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: "正しいパスワードを入力してください。"
                            }
                        ]}
                    >
                        <Input.Password
                            placeholder='半角英数字８文字以上'
                            iconRender={
                                visible => (visible ?
                                    <img alt='eye' src='/assets/icons/eye.svg' />
                                    :
                                    <img alt='eye_off' src='/assets/icons/eye_off.svg' />
                                )
                            }
                            prefix={<img alt='password' src='/assets/icons/password.svg' />}
                            style={{
                                padding: '10px',
                                height: 'auto',
                                borderRadius: '10px',
                                boxShadow: '0px 0px 8px #293C4F29'
                            }}
                        />
                    </Form.Item>
                    <div className='login-forgot-password'>
                        <a href='reset-password'>パスワードをお忘れの方</a>
                    </div>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            style={{
                                width: '100%',
                                padding: '8px',
                                height: 'auto',
                                borderRadius: '8px',
                                backgroundColor: '#00aaf0',
                                fontWeight: '600'
                            }}
                        >
                            ログイン
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={{ span: 18, offset: 3 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }} className='login-register'>
                <a href='register'>管理者の方はこちら</a>
            </Col>
            <span className='footer' style={{ backgroundColor: '#FFFFFF' }} >
                <p>THE PHAGE, Inc.</p>
            </span>
        </Row>
    );
};

export default Login;