import axios from "axios";
import { headers } from "../../utils/constant";
import { processCSV } from "../../utils/processCSV";

export async function upload(body: any) {
    var formData = new FormData();
    formData.append('files', body.files);
    var response: any;
    await axios({
        method: 'POST',
        url: 'admin/upload',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            ...headers
        },
        responseType: 'json'
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response;
    });
    return response;
}

export async function download() {
    var response: any;
    await axios({
        method: 'POST',
        url: 'admin/download',
        headers: headers,
        responseType: 'json'
    }).then(res  => {
        response = res.data;
    }).catch(res => {
        response = res.response;
    });
    if(response?.status) {
        processCSV(response?.data)
    }
    return response;
}