import { Button, Col, Form, Input, message, Row } from "antd";
import React, { useState } from "react";
import { FormInstance } from "rc-field-form";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword, sendEmail } from "../../services/User/api";

const ResetPassWord: React.FC = () => {

  const params = useParams();
  const navigate = useNavigate()
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormInstance>();
  const onSubmit = async (values: any) => {
    if (!values?.password || !params?.token) {
      const res = await sendEmail({ 'email': values?.email })
      if (res?.status) {
        message.success(res?.message);
        setIsSubmit(true);
        form.resetFields();
      }
      else message.error(res?.message);
    }
    else {
      setLoading(true);
      const res = await resetPassword({ 'token': params.token, 'password': values.password });
      if (res?.status) {
        message.success(res?.message);
        navigate('/')
        form.resetFields();
      }
      else {
        let error = '';
        if (res?.errors)
          Object.keys(res?.errors)?.forEach(element => {
            error += (res?.errors[element] && res?.errors[element][0]) ? res?.errors[element][0] : ''
          })
        else error = res?.message;
        message.error(error);
      }
      setLoading(true);
    }
  };

  return (
    <div>
      <div className="reset-password">
        {(params && params.token) ?
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              <Col xs={{ span: 24 }} sm={{ span: 12, offset: 6 }} md={{ span: 6, offset: 9 }} style={{ padding: '40px 10px' }}>
                <h1>パスワードの再設定</h1>
                <p style={{ textAlign: 'center' }}>パスワードを再設定するためのメールをお送りします。</p>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12, offset: 6 }} md={{ span: 6, offset: 9 }} style={{ padding: '40px' }}>
                <Form
                  form={form}
                  onFinish={(value) => onSubmit(value)}
                  layout="vertical"
                >
                  <Form.Item label="新しいパスワードを入力" name="password">
                    <Input
                      type="password"
                      placeholder="新しいパスワードを入力"
                      prefix={<img alt='' src='/assets/icons/password.svg' />}
                      min={8}
                      style={{
                        padding: "10px",
                        boxShadow: "0px 0px 8px #293C4F29",
                        borderRadius: "8px",
                        color: "#CED3D9",
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="reset-button"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      確認します
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Col>
          </Row>
          :
          <Row style={{ width: '100%', alignItems: 'center' }}>
            <Col span={24}>
              <Col xs={{ span: 24 }} sm={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }} style={{ padding: '10px' }}>
                <h1>パスワードの再設定</h1>
                {isSubmit ?
                  <span>
                    <p> 入力されたメールアドレス宛に、パスワード再設定のためのURLが記載されたメールを送信しました。そのURLをクリックして、手続きを進めてください。</p>
                    <p>
                      登録されていないメールアドレスをご入力されたときは、メールは送信されません。</p>
                  </span>
                  : <p style={{ textAlign: 'center' }}>パスワードを再設定するためのメールをお送りします。</p>}
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12, offset: 6 }} md={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 9 }} style={{ padding: '40px' }}>
                {!isSubmit && (
                  <>
                    <Form
                      form={form}
                      onFinish={(value) => onSubmit(value)}
                      layout="vertical"
                    >
                      <Form.Item label="メールアドレス" name="email">
                        <Input
                          placeholder="メールアドレス"
                          type='email'
                          prefix={<img alt='' src='/assets/icons/email.svg' />}
                          min={8}
                          style={{
                            padding: "10px",
                            boxShadow: "0px 0px 8px #293C4F29",
                            borderRadius: "8px",
                            color: "#CED3D9",
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          className="reset-button"
                          type="primary"
                          htmlType="submit"
                        >
                          送 信
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                )}
                {isSubmit && (
                  <div>
                    <Button className="reset-button" type="primary" onClick={() => { navigate('/login') }}>閉じる</Button>
                  </div>
                )}
              </Col>
            </Col>
          </Row>
        }
      </div>
      <span className='footer' style={{ backgroundColor: '#FFFFFF' }} >
        <p>THE PHAGE, Inc.</p>
      </span>
    </div>
  );
};
export default ResetPassWord;
