import axios from "axios";
import { headers } from "../../utils/constant";

export async function getGlucoseData(body: API.GlucoseRequest) {
  var response: any; 
  await axios({
    method: 'get',
    url: 'admin/glucose/graph',
    headers: headers,
    responseType: 'json',
    params: body,
  }).then(res => {
    response = res?.data;
  }).catch(res => {
    response = res.response?.data;
  });
  if(response?.status)
  return response.data;
  return null;
}

export async function getMealGlucoseData(body: API.MealGlucoseRequest) {
  var response: any; 
  await axios({
    method: 'get',
    url: 'admin/glucose/meal/graph',
    headers: headers,
    responseType: 'json',
    params: body,
  }).then(res => {
    response = res?.data;
  }).catch(res => {
    response = res.response?.data;
  });
  if(response?.status)
  return response.data;
  return null;
}

export async function getHba1cPrediction(body : API.Hba1cRequest) {
  var response: any; 
  await axios({
    method:'get',
    url: '/admin/glucose/hba1c',
    headers: headers,
    responseType: 'json',
    params: body,
  })
  .then(res => {
    response = res.data;
  }).catch(res => {
    response = res?.response?.data;
  });
  if(response?.status)
  return response?.data;
  else return null
}

export async function getHungryGlucose(body : API.HungryGlucoseRequest) {
  var response: any; 
  await axios({
    method:'get',
    url: '/admin/glucose/hungry',
    headers: headers,
    responseType: 'json',
    params: body,
  }).then(res => {
    response = res.data;
  }).catch(res => {
    response = res?.response?.data;
  });
  return response;
}