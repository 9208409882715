import axios from 'axios';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import { API_URL, AUTH_ACCESS_TOKEN } from './utils/constant';
import { me } from '../src/services/User/api';
import { createContext, useEffect } from 'react';
import { useState } from 'react';
import { privateRoutes, routes } from './config/router';
import Loading from './components/Loading';

export const AppContext = createContext<any>({ user: null, setIsLoggedIn: null});

function App() {
  axios.defaults.baseURL = API_URL;
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(AUTH_ACCESS_TOKEN)}`
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();  
  const [user, setUser] = useState<API.User | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyLogin = async () => {
      const response = await me();
      setUser(response.status ? response.data : {});
      setIsLoggedIn(response.status);
      setLoading(false)
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(AUTH_ACCESS_TOKEN)}`
    verifyLogin();
  }, [isLoggedIn]);

  return (
    <div>
      <AppContext.Provider value={{ user, setIsLoggedIn }}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={loading ? <Loading /> : (isLoggedIn ? <Navigate to='/home' /> : <Navigate to='/login' />)} />
            {routes.map((route: any) =>
              <Route path={route.path} element={route.element} />)}
            <Route element={<Layout whiteFooter={true} />} >
              {privateRoutes.filter(route => route.whiteFooter).map((route: any) =>
                <Route path={route.path} element={loading ? <Loading /> : (isLoggedIn ? route.element : <Navigate to='/login' />)} />
              )}
            </Route>
            <Route element={<Layout whiteFooter={false} />} >
              {privateRoutes.filter(route => !route.whiteFooter).map((route: any) =>
                <Route path={route.path} element={loading ? <Loading /> : (isLoggedIn ? route.element : <Navigate to='/login' />)} />
              )}
            </Route>
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </div >
  );
}

export default App;
