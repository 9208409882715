import axios from "axios";
import { headers } from "../../utils/constant";

export async function analytics(body: API.DisplayAnalytics) {
    var response: any;
    await axios({
        method: 'POST',
        url: '/admin/analytics',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response;
    });
    if (response?.status) {
        response = response?.data;
    }
    else response = [];
    return response;
}