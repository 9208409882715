import { Col, Dropdown, Menu, message, Row, Space } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { logout } from '../../services/User/api';
import { AUTH_ACCESS_TOKEN } from '../../utils/constant';

const Header: React.FC<{ user: any }> = (props) => {

    const navigate = useNavigate()
    const { setIsLoggedIn } = useContext(AppContext);


    const logoutUser = async () => {
        const res = await logout();
        if (res.status) {
            localStorage.removeItem(AUTH_ACCESS_TOKEN)
            setIsLoggedIn(false)
            navigate('/login');
        }
        else message.error('Logout fail')
    }

    var MyDate = new Date();
    const MyDateString = MyDate.getFullYear() + '年' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '月' + ('0' + MyDate.getDate()).slice(-2) + '日';

    return (
        <Row style={{ fontWeight: '600', fontSize: '15px', padding: '15px 50px', alignItems: 'center', display: 'flex', fontFamily: 'Noto Sans JP' }}>
            <Col span={8} style={{ textAlign: 'left', cursor: 'pointer', color: '#293C4F' }}>
                <Space
                    onClick={() => {
                        navigate('/home');
                    }}
                >
                    <img
                        alt=''
                        src='/assets/icons/home.svg' /
                    >
                    {MyDateString}
                </Space>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
                <img
                    onClick={() => {
                        navigate('/home');
                    }}
                    alt=''
                    src='/assets/icons/logo.svg'
                    style={{ height: 37, width: 140, cursor: 'pointer' }}
                />
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
                <Space>
                    <img alt='' src='/assets/icons/user.svg' />
                    <Dropdown
                        placement='bottomLeft'
                        overlay={
                            <Menu>
                                <Menu.Item onClick={logoutUser}>
                                    logout
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <span style={{ cursor: 'pointer' }}>{props && props.user ? props.user.name : ''}</span>
                    </Dropdown>
                </Space>
            </Col>
        </Row>
    );
};

export default Header;