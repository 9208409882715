import axios from "axios";
import { headers } from "../../utils/constant";


export async function getDoctors() {
    var response: any; 
    await axios({
        method: 'GET',
        url: '/admin/doctor',
        params: {},
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data?.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}

export async function assign(body: API.AssignPatientsForDoctorRequest) {
    var response: any; 
    await axios({
        method: 'GET',
        url: '/admin/doctor/assign',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res?.data;
    }).catch(res => {
        response = res.response;
    });
    return response;
}