export const API_URL = process.env.API_URL || 'https://api.glucoseflight.com'

export const AUTH_ACCESS_TOKEN = 'auth_access_token';
export const AUTH_ACCESS_TOKEN_EXPIRED_AT = 'auth_access_token_expired_at';
export const AUTH_REFRESH_TOKEN = 'auth_refresh_token';


export const headers = {
    'Accept': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
    'withCredentials': true,
}

export const ROLE = {
    DOCTOR: 1,
    CRC: 2,
    PHAGE: 3,
}

export const REMOTEWEEK = [1,5,9];

export const GENDER = {
    UNKNOWN: 0,
    MALE: 1,
    FEMALE: 2,
}

export const GENDER_TEXT = {
    0: 'その他',
    1: '男性',
    2: '女性',
}

export const TIMER = {
    HOME: 'display_home',
    PATIENT: 'display_patient',
}
