import { Avatar, Button, Col, Input, message, Row, Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css'
import { getPatients, getPatientsByDoctorId } from '../../services/Patient/api';
import { AppContext } from '../../App';
import { REMOTEWEEK, ROLE } from '../../utils/constant';
import EditPatient from '../Patient/components/EditPatient';
import { download } from '../../services/File/api';
import { analytics } from '../../services/Analytics/api';
import AssginmentModal from './components/AssignmentModal';
import { assign } from '../../services/Doctor/api';

interface DataType {
    key: string;
    id?: string;
    name: string;
    avatar?: string;
    hypoglycemia: number;
    hyperglycemia: number;
    message: number;
    status: string;
    time: string;
}

const Home: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AppContext)
    const [data, setData] = useState<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [patients, setPatients] = useState<any>();
    const [addPatientVisible, setAddPatientVisible] = useState<boolean>(false);
    const [isAssign, setIsAssign] = useState<boolean>(false);
    const [showAssign, setShowAssign] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [doctorId, setDoctorID] = useState<number>();

    const handleUser = async (record: any) => {
        navigate('/patient/' + record.pa_id)
    }

    function compare(a, b) {
        const next_a = countDayToTheNextWeek(a?.started_at);
        const next_b = countDayToTheNextWeek(b?.started_at);
        if (a?.started_at === null && a?.started_at === null) return 0;
        if (next_a < next_b || b?.started_at === null) {
            return -1;
        }
        if (next_a > next_b || a?.started_at === null) {
            return 1;
        }
        return 0;
    }

    const fetchPatientsData = async () => {
        let listPatient = await getPatients({});
        if (listPatient) {
            listPatient = listPatient?.sort(compare);
        }
        setPatients(listPatient ?? []);
        setData(listPatient ?? []);
    }

    const fetchPatientsByDoctorId = async () => {
        if (doctorId) {
            const res = await getPatientsByDoctorId({ do_id: doctorId })
            setSelectedRowKeys(res)
        }
    }

    useEffect(() => {
        fetchPatientsData();
    }, [addPatientVisible])

    useEffect(() => {
        setAddPatientVisible(location.hash === '#form')
    }, [location.hash])

    useEffect(() => {
        const timer = setInterval(() => {
            analytics({ 'do_id': user.id, display: 'home', 'timer': 1 })
        }, 60 * 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        setIsAssign(!!doctorId)
    }, [selectedRowKeys])

    useEffect(() => {
        fetchPatientsByDoctorId()
    }, [doctorId])

    const onSearch = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value) {
                setPatients(data.filter(patient => patient?.pa_id?.includes(e.target.value) || patient?.name?.includes(e.target.value)))
            }
            else setPatients(data);
        }
    }

    const getWeek = (started_at: string) => {
        const createdAt = new Date(started_at?.split(' ')[0]);
        return Math.floor((((new Date()).getTime() - createdAt.getTime()) / (24 * 60 * 60 * 1000)) / 7) + 1
    }

    const countDayToTheNextWeek = (started_at: string) => {
        const createdAt = new Date(started_at);
        const now = new Date();
        createdAt.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
        return 7 - Math.floor((now.getTime() - createdAt.getTime()) / (24 * 60 * 60 * 1000)) % 7
    }

    const getTheNextDay = (started_at: string) => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const day = countDayToTheNextWeek(started_at);
        now.setDate(now.getDate() + day);
        return (now.getMonth() + 1) + '月' + now.getDate() + '日';
    }

    const handleEditPatient = () => {
        navigate(location.hash === '#form' ? '' : '#form')
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };


    const handleAssign = async () => {
        if (!isAssign || !doctorId) {
            setModalVisible(!showAssign)
        }
        else {
            const res: any = await assign({ do_id: doctorId, pa_ids: selectedRowKeys?.map(item => item.toString()) })
            if (res.status) {
                setSelectedRowKeys([]);
                setDoctorID(undefined)
                setIsAssign(false);
                setShowAssign(false);
                message.success(res?.message);
            }
            else {
                message.error(res?.message);
            }
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'pa_id',
            key: 'pa_id',
        },
        {
            title: '患者名',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name?.localeCompare(b.name, 'ja'),
            render: (record: any, values: any) =>
                <div style={{ textAlign: 'left' }}>
                    <Space size={'large'}>
                        <Avatar className='user' size={'large'} src='/assets/icons/patient.svg' />
                        <div>
                            <span>{values.kana_name}</span><br />
                            <span className='row-kana-name'>{record}</span>
                        </div>
                    </Space>
                </div>
        },
        {
            title: '低血糖',
            dataIndex: 'hypoglycaemia',
            key: 'hypoglycaemia',
            sorter: (a: any, b: any) => {
                if (a?.hypoglycaemia === null && b?.hypoglycaemia === null) return 0;
                if (a?.hypoglycaemia > b?.hypoglycaemia || b?.hypoglycaemia === null) return 1;
                if (a?.hypoglycaemia < b?.hypoglycaemia || a?.hypoglycaemia === null) return -1;
                return 0;
            },
            render: (record: any) =>
                <span
                    style={{
                        color: '#DC4602',
                    }}
                >
                    {record == null ? '-' : <span>{record} <b>回</b></span>}
                </span>,
        },
        {
            title: '高血糖',
            dataIndex: 'hyperglycaemia',
            key: 'hyperglycaemia',
            sorter: (a: any, b: any) => {
                if (a?.hyperglycaemia === null && b?.hyperglycaemia === null) return 0;
                if (a?.hyperglycaemia > b?.hyperglycaemia || b?.hyperglycaemia === null) return 1;
                if (a?.hyperglycaemia < b?.hyperglycaemia || a?.hyperglycaemia === null) return -1;
                return 0;
            },
            render: (record: any) =>
                <span>
                    {record == null ? '-' : <span>{record} <b>回</b></span>}
                </span>,
        },
        {
            title: 'メッセージ',
            key: 'message',
            dataIndex: 'message',
            render: (_: any, record: any) => {
                const insulin = record?.insulin;
                let diff = 0;
                if (insulin && insulin.seen == false) {
                    let time = new Date(insulin.date + ' ' + insulin.time);
                    diff = Math.floor(((new Date()).getTime() - (time.getTime())) / 3600000) + 24;
                }
                return (<span>
                    {insulin ? (insulin.seen) ? <b>既読</b> : <span style={{ color: '#0d94da' }}><b>未読</b> <br /> {diff} 時間前</span> : <b>空欄</b>}
                </span>)
            }
        },
        {
            title: 'ステータス',
            key: 'started_at',
            dataIndex: 'started_at',
            width: 150,
            render: (started_at: any) => {
                return (
                    <>                    {
                        started_at && <div className='row-create-at'>
                            {getWeek(started_at)} < b > 週</b >
                        </div >
                    }</>
                )
            }
        },
        {
            title: '次回診療日',
            key: 'started_at',
            dataIndex: 'started_at',
            render: (started_at: any) =>
                <div>
                    {started_at && <Space>
                        {getTheNextDay(started_at)}
                        {!REMOTEWEEK.includes(getWeek(started_at) + 1) &&
                            <Tag color="#de4300">遠隔</Tag>
                        }
                    </Space>}
                </div>
        },
    ];

    return (
        <div
            className='body'
        >
            {!addPatientVisible ? <>
                <Row>
                    <Col span={24}>
                        <h1 style={{ fontWeight: 'bold', fontFamily: 'Noto Sans JP', color: '#293C4F' }}>ホーム / 患者一覧</h1>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Input
                            size="large"
                            placeholder="患者名/ID 検索"
                            prefix={<img alt='' src='/assets/icons/search.svg' style={{ paddingRight: '10px' }} />}
                            onKeyDown={(e) => onSearch(e)}
                            style={{
                                borderRadius: '20px'
                            }}
                        />
                    </Col>
                    <Col span={16}>
                        <Row justify='end'>
                            <Space size={'large'}>
                                <span>計{patients?.length || 0}人</span>
                                {
                                    user && user['role'] === ROLE.PHAGE &&
                                    <Button
                                        style={{ fontWeight: 'bold', fontFamily: 'Noto Sans JP' }}
                                        onClick={handleAssign} type='primary' className={isAssign ? 'btn-assign' : 'bnt-download'}> {isAssign ? 'OK' : 'Assign'} </Button>
                                }
                                {
                                    user && (user['role'] === ROLE.DOCTOR || user['role'] === ROLE.CRC) &&
                                    <Button style={{ fontWeight: 'bold', fontFamily: 'Noto Sans JP' }} onClick={handleEditPatient} type='primary' className='bnt-download'> 新規登録 </Button>
                                }
                                {
                                    user && (user['role'] === ROLE.CRC || user['role'] === ROLE.PHAGE) &&
                                    <Button
                                        type='primary'
                                        className='btn-upload'
                                        onClick={() => { navigate('/upload') }}
                                    >
                                        UPLOAD
                                    </Button>
                                }
                                {
                                    user && user['role'] === ROLE.PHAGE &&
                                    <Button
                                        type='primary'
                                        loading={loading}
                                        onClick={async () => {
                                            setLoading(true);
                                            await download();
                                            setLoading(false);
                                        }}
                                        className='bnt-download'
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        DOWNLOAD
                                    </Button>
                                }
                            </Space>
                        </Row>
                    </Col>
                </Row>
                <Table
                    key={'id'}
                    rowKey="id"
                    rowClassName={() => "row-class-name-pantient"}
                    onRow={(record) => {
                        return {
                            onClick: event => { handleUser(record) },
                        };
                    }}
                    rowSelection={
                        isAssign ? {
                            selectedRowKeys,
                            onChange: onSelectChange,
                        } :
                            {
                                selectedRowKeys,
                                onChange: onSelectChange,
                                hideSelectAll: true,
                                renderCell: () => '',
                            }
                    }
                    columns={columns}
                    dataSource={patients}
                    pagination={{ defaultPageSize: 15 }}
                />
            </> : <EditPatient handleEditPatient={handleEditPatient} />}

            <AssginmentModal
                doctor={[]}
                visible={modalVisible}
                setShowAssign={setShowAssign}
                handleVisible={setModalVisible}
                setDoctorID={setDoctorID}
            />
        </div >
    );
};

export default Home;