import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import './index.css';
import { getGlucoseData, getMealGlucoseData } from '../../services/Chart/api';
import moment from 'moment';
highchartsMore(Highcharts);

// custom scatter to rectangle
Highcharts.SVGRenderer.prototype.symbols.pointer = function (x, y, w, h) {
  return [
    'M', x, y,
    'C', x, y - h, x + w, y - h, x + w, y,
    'C', x + w, y + h, x, y + h, x, y,
    'z'
  ];

};

const HighChart: React.FC<{ pa_id: string, start_date: string, days: number, setLoadingDiagram: any }> = (props) => {

  const [data, setdata] = useState<any>(undefined);
  const [dataMorning, setdataMorning] = useState<any>(undefined);
  const [dataLunch, setdataLunch] = useState<any>(undefined);
  const [dataDinner, setdataDinner] = useState<any>(undefined);
  const [windowWith, setWindowWith] = useState<number>(window.innerWidth - 110);
  const [display, setDisplay] = useState<string>('flex');
  const [graphSize, setGraphSize] = useState<number[]>([2.5, 1]);

  const title = ['朝食', '昼食', '夕食'];

  const fetchData = async () => {
    props.setLoadingDiagram(true);
    let fixed = {
      'pa_id': props?.pa_id,
      'start_date': props?.start_date,
      'duration': props?.days,
    }
    let fixed1 = {
      'pa_id': props?.pa_id,
      'start_date': props?.start_date,
      'days': props?.days,
      'hours': 3,
      'type': 'breakfast',
    }
    let fixed2 = {
      'pa_id': props?.pa_id,
      'start_date': props?.start_date,
      'days': props?.days,
      'hours': 3,
      'type': 'lunch',
    }
    let fixed3 = {
      'pa_id': props?.pa_id,
      'start_date': props?.start_date,
      'days': props?.days,
      'hours': 3,
      'type': 'dinner',
    }
    const data_fixed = await getGlucoseData(fixed);
    setdata(data_fixed);
    const data_breakfast = await getMealGlucoseData(fixed1);
    setdataMorning(data_breakfast);
    const data_lunch = await getMealGlucoseData(fixed2);
    setdataLunch(data_lunch);
    const data_dinner = await getMealGlucoseData(fixed3);
    setdataDinner(data_dinner);
    props.setLoadingDiagram(false);
  }

  useEffect(() => {
    fetchData();
  }, [props?.start_date])

  useEffect(() => {
    function handleResize() {
      const width: number = window.innerWidth - 110;
      setWindowWith(width);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWith < 1000) {
      setDisplay('inline-block')
      setGraphSize([2.5, 1])
    }
    else {
      setDisplay('flex')
      setGraphSize([1, 1 / 3])
    }
  }, [windowWith])

  const charFixedData = {
    title: {
      text: '夜間血糖（眠前）'
    },

    tooltip: {
      valueSuffix: 'mg/dL'
    },

    legend: {
      enabled: false,
    },

    xAxis: {
      minorGridLineDashStyle: 'dot',
      minorGridLineColor: '#293C4F',
      minorTickInterval: 60 / data?.distance,
      minorTickLength: 60 / data?.distance,
      tickWidth: 0,
      tickInterval: 60 / data?.distance,
      labels: {
        formatter: function (this: any, ok) {
          const value = this.value === 0 ? 0 : (this.value * data?.distance / 60);
          const start_date = new Date('2022-11-11 ' + data?.start_time);
          start_date.setHours(start_date.getHours() + value);
          var hours = '' + start_date.getHours();
          if (hours.length < 2) hours = '0' + hours;
          if (this.isLast && !this.isFirst) {
            hours += '時間'
          }
          return hours;
        }
      }
    },

    yAxis: {
      title: {
        enabled: false,
      },
      showFirstLabel: false,
      minorTickInterval: 'none',
      minorTickLength: 0,
      tickWidth: 2,
      tickInterval: 50,
      tickColor: '#293C4F',
      softMin: 0,
      softMax: 350,
      labels: {
        formatter: function (this: any) {
          return this.isLast ? this.value + '</b><br>' + ' mg/dL' : this.value;
        }
      },
    },

    chart: {
      width: display === 'flex' ? windowWith * graphSize[0] / (graphSize[0] + 3 * graphSize[1]) : windowWith
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
        marker: {
          enabled: false
        },
      },
      scatter: {
        marker: {
          enabled: true,
          symbol: 'pointer',
          radius: 2.5,
          width: 2,
          height: 4,
          fillColor: '#A7A7A7',
        },
      }
    },


    series:
      [
        {
          type: 'scatter',
          name: '',
          color: '#A7A7A7',
          tooltip: {
            pointFormatter: function () {
              var point: any = this;
              return `time: <b>${moment(data.start_time, 'H:i:s').add(point.x * data?.distance, 'minutes').format('HH:mm:ss')}</b><br/>glucose: ${point.y} mg/dL`
            },
            shared: true
          },
          data:
            data?.quartileInc0 && data?.quartileInc4 && data?.total_times && data?.total_times > 1 ? [...Object.values(data?.quartileInc0 || {}).map((item: any, index) => [index, item[1]]), ...Object.values(data?.quartileInc4 || {}).map((item: any, index) => [index, item[1]])] : []
        },
        {
          type: 'areasplinerange',
          name: '10-90',
          color: '#BBD8E7',
          data: (data?.total_times && data?.total_times > 1) ? Object.values(data?.percentile || {}) : []
        },
        {
          type: 'areasplinerange',
          name: '25-75',
          color: '#80C0DF',
          data: (data?.total_times && data?.total_times > 1) ? Object.values(data?.quartileInc13 || {}) : []
        },
        {
          type: 'spline',
          name: 'median',
          color: '#FFFFFF',
          data: (data?.total_times && data?.total_times > 1) ? Object.values(data?.median || {}) : [],
        },
        {
          name: '低血糖線',
          color: '#FF1D25',
          data: (data?.total_times && data?.total_times > 1) ? [
            [0, 70],
            [data?.total_times - 1, 70]
          ] : [],
        },
        {
          type: 'spline',
          name: 'expectation',
          color: '#FFFF00',
          data: (data?.total_times && data?.total_times > 1) ? [
            [0, data?.gTarget],
            [data?.total_times - 1, data?.K],
          ] : []
        },
        {
          type: 'scatter',
          name: 'median',
          dataLabels: {
            enabled: true,
            style: {
              "textOutline": "none"
            }
          },
          marker: {
            symbol: 'circle',
            fillColor: '#000000',
            radius: 3
          },
          data: (data?.total_times && data?.total_times > 1) ? [
            [0, (Object.values(data?.median || {}))[0]],
            [data?.total_times - 1, (Object.values(data?.median || {}))[Object.values(data?.median || {}).length - 1]],
          ] : []
        },
      ]
  }
  const ChartData = [dataMorning, dataLunch, dataDinner].map((glucose, index) => {
    try {
      return {
        title: {
          text: title[index]
        },

        legend: {
          enabled: false,
        },

        xAxis: {
          minorGridLineColor: '#293C4F',
          minorTickInterval: 4,
          minorTickLength: 4,
          tickWidth: 0,
          tickInterval: 4,
          labels: {
            formatter: function (this: any) {
              return this.value === 0 ? 0 : ('+' + this.value / 4 + (this.isLast ? 'h' : ''));
            }
          }
        },

        yAxis: {
          title: {
            enabled: false,
          },
          showFirstLabel: false,
          minorTickInterval: 'none',
          minorTickLength: 0,
          tickWidth: 2,
          tickInterval: 50,
          tickColor: '#293C4F',
          softMin: 0,
          softMax: 350,
        },

        chart: {
          width: display === 'flex' ? windowWith * graphSize[1] / (graphSize[0] + 3 * graphSize[1]) : windowWith / 3
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false
            },
            marker: {
              enabled: false
            },
          },
          scatter: {
            marker: {
              enabled: true,
              symbol: 'pointer',
              radius: 2.5,
              width: 2,
              height: 4,
              fillColor: '#A7A7A7',
            },
          }
        },
        series:
          [
            {
              type: 'scatter',
              name: '',
              color: '#A7A7A7',
              tooltip: {
                pointFormatter: function () {
                  var point: any = this;
                  return `time: <b>${point.x * data?.distance} 分</b><br/>glucose: ${point.y} mg/dL`
                },
                shared: true
              },
              data: glucose?.quartileInc0 && glucose?.quartileInc4 ? [...Object.values(glucose?.quartileInc0 || {}).map((item: any, index) => [index, item[1]]), ...Object.values(glucose?.quartileInc4 || {}).map((item: any, index) => [index, item[1]])] : []
            },
            {
              type: 'areasplinerange',
              name: '10-90',
              color: '#BBD8E7',
              data: Object.values(glucose?.percentile || {})
            },
            {
              type: 'areasplinerange',
              name: '25-75',
              color: '#80C0DF',
              data: Object.values(glucose?.quartileInc13 || {})
            },
            {
              type: 'spline',
              name: 'median',
              color: '#FFFFFF',
              data: Object.values(glucose?.median || {}),
            },
            {
              name: '低血糖線',
              color: '#FF1D25',
              data: glucose?.total_times ? [
                [0, 70],
                [glucose?.total_times - 1, 70]
              ] : [],
            },
            {
              type: 'spline',
              name: 'expectation',
              color: '#FFFF00',
              data: [
                [0, glucose?.K],
                [glucose?.total_times / 2, glucose?.gTarget],
                [glucose?.total_times - 1, glucose?.K],
              ],
            },
            {
              type: 'scatter',
              name: 'median',
              dataLabels: {
                enabled: true
              },
              marker: {
                symbol: 'circle',
                fillColor: '#000000',
                radius: 3
              },
              data: [
                [0, (Object.values(glucose?.median || {}))[0]],
                [glucose?.total_times - 1, (Object.values(glucose?.median || {}))[Object.values(glucose?.median || {}).length - 1]],
              ]
            },
          ]
      }
    } catch (error) {
      console.log('error to convert chart data')
    }
  })

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: display }}>
        <HighchartsReact
          containerProps={{ style: { height: "100%" } }}
          highcharts={Highcharts}
          options={charFixedData}
        >
        </HighchartsReact>
        <div style={{ display: 'flex' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={ChartData[0]}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={ChartData[1]}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={ChartData[2]}
          />
        </div>
      </div>
      {!data?.K &&
        <div className='highcharts-warning'>
          グラフ表示のためには血糖値と目標血糖値が必要です。
        </div>}
    </div>
  )
}

export default HighChart;