import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../../App";
import Footer from "../Footer";
import Header from '../Header';


const Layout: React.FC<{ whiteFooter: boolean }> = (props) => {

    const { user } = useContext(AppContext);
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 0) {
            setVisible(true)
        }
        else if (scrolled <= 0) {
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header user={user} />
            <Outlet />
            <Footer whiteFooter={props?.whiteFooter} />
            <div
                className="scroll-top"
                hidden={!visible}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
                <img alt='' src='/assets/icons/top.svg' />
            </div>
        </div>
    );
};

export default Layout;