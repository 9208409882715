import axios from "axios";
import { headers } from "../../utils/constant";

export async function login(body: API.LoginParams) {
    var response: any; 
    await axios({
        method: 'POST',
        url: 'admin/auth/login',
        data: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}


export async function logout() {
    var response: any; 
    await axios({
        method: 'POST',
        url: 'admin/auth/logout',
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}


export async function register(body: API.RegisterParams) {
    var response: any; 
    await axios({
        method: 'POST',
        url: 'admin/auth/register',
        data: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}

export async function me() {
    var response: any;
    await axios({
        method: 'GET',
        url: '/admin/auth/me',
        responseType: 'json',
        headers: headers,
    }).then(res => {
        if(res.data.status){
            response = res.data
        }
    }).catch(e => {
        response = e.response.data
    })
    return response;
}

export async function sendEmail(body: API.EmailParams) {
    var response: any; 
    await axios({
        method: 'POST',
        url: 'admin/auth/reset',
        data: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}

export async function resetPassword(body: API.ResetPasswordParams) {
    var response: any; 
    await axios({
        method: 'POST',
        url: 'admin/auth/reset/confirm',
        data: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}