import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../App';
import { analytics } from '../../services/Analytics/api';
import { getSuggestedInsulin } from '../../services/Insulin/api';
import EditInsulin from './components/EditInsulin';
import EditPatient from './components/EditPatient';
import PatientDetail from './components/PatientDetail';
import RecordAndNote from './components/RecordAndNote';
import Summary from './components/Summary';
import './index.css'

const Pantient: React.FC = () => {

    let params = useParams();
    const { user } = useContext(AppContext);
    const [showEditPatient, setShowEditPatient] = useState<boolean>(false);
    const [insulin, setInsulin] = useState<API.SuggestedInsulinResponse[]>([]);

    const handleEditPatient = () => {
        setShowEditPatient(!showEditPatient);
    }

    const fetchInsulin = async () => {
        const data = await getSuggestedInsulin({ 'pa_id': params.id || '' });
        setInsulin(data)
    }

    useEffect(() => {
        fetchInsulin()
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            analytics({ 'do_id': user.id, pa_id: params.id, display: 'home', 'timer': 1 })
        }, 60 * 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <div className='body' >
            {!showEditPatient ?
                <>
                    <PatientDetail handleEditPatient={handleEditPatient} />
                    <div style={{ padding: '20px 0px' }}>
                        <Summary />
                        <EditInsulin insulin={insulin.length > 0 ? insulin[0] : {}} fetchInsulin={fetchInsulin} />
                        <RecordAndNote insulin={insulin.length > 0 ? insulin : []} />
                    </div>
                </>
                :
                <EditPatient handleEditPatient={handleEditPatient} />
            }
        </div>
    );
};

export default Pantient;