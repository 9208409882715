import { Spin } from "antd";

const Loading: React.FC = () => {


    return (
        <div className='body' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Spin size="large" />
        </div>
    );
};

export default Loading;