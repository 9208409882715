import { Avatar, Button, Col, Row, Slider, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { SliderMarks } from 'antd/lib/slider';
import { useParams } from 'react-router-dom';
import { GENDER_TEXT } from '../../../utils/constant';
import '../index.css';
import { getPatientById } from '../../../services/Patient/api';
import { formatDate, formatDateString } from '../../../utils/helper';
import { getHba1cPrediction, getHungryGlucose } from '../../../services/Chart/api';

const SLIDER_LENGTH = 9;

const PatientDetail: React.FC<{ handleEditPatient: any }> = (props: any) => {

    let params = useParams();


    const sliderArray = Array.from(Array(SLIDER_LENGTH).keys())

    const [patientDetail, setPatientDetail] = useState<any>();
    const [slidePoint, setSlidePoint] = useState<number>(0);
    const [marks, setMarks] = useState<SliderMarks>({});
    const [hungryGlucose, setHungryGlucose] = useState<number>();
    const [hba1c, setHba1c] = useState<number>();
    const [editImg, setEditImg] = useState<string>('/assets/icons/external.svg');

    const fetchDataAndSetMarks = async () => {
        const data = await getPatientById({ 'pa_id': params.id || '' });
        setPatientDetail(data);
        const hba1cPrediction = await getHba1cPrediction({ 'pa_id': params.id || '' });
        setHba1c(hba1cPrediction)
        const hungry = await getHungryGlucose({ 'pa_id': params.id || '' });
        if (hungry?.status) {
            setHungryGlucose(hungry?.data)
        }

        const mark: SliderMarks = {}
        sliderArray.map(item => {
            mark[item] = 'w' + (item) + (item === 4 ? '/来院' : '');
        })
        mark[0] = 'start';
        mark[sliderArray.length - 1] = 'goal';
        if (data && data.started_at) {
            const createdAt = new Date(data.started_at);
            const diff = Math.floor(((new Date()).getTime() - createdAt.getTime()) / (7 * 24 * 60 * 60 * 1000));
            setSlidePoint(diff);
        }
        setMarks(mark)

    }


    useEffect(() => {
        fetchDataAndSetMarks();
    }, [])

    const getAge = (dateString: string) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
            m += 12;
        }
        return age + '歳' + m + 'ヶ月';
    }


    return (
        <Row gutter={16} className='patient-detail'>
            <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                <Row>
                    <Col xs={3} sm={4}>
                        <Col span={4}>
                            <Avatar
                                className='patient-img'
                                size={80}
                                icon={<img alt='user_image' src='/assets/icons/patient.svg' />}
                            />
                        </Col>
                    </Col>
                    <Col xs={21} sm={20}>
                        <Row>
                            <p>
                                患者ID: {patientDetail?.pa_id}
                            </p>
                        </Row>
                        <Row className='edit-patient-title'>
                            <Col span={24}>
                                <Space className='patient-title' size={'large'}>
                                    <span className='patient-text'> {(patientDetail?.name || '') + (patientDetail?.kana_name ? (' (' + (patientDetail?.kana_name) + ')') : '')} </span>
                                    <Button
                                        className='button-edit'
                                        size={'large'}
                                        onClick={props.handleEditPatient}
                                        onMouseOver={(e): void => {
                                            setEditImg('/assets/icons/external_selection.svg');
                                        }}
                                        onMouseOut={(e): void => {
                                            setEditImg('/assets/icons/external.svg');
                                        }}
                                    >
                                        <Space>
                                            <img
                                                className='edit-img'
                                                alt='edit_patient'
                                                src={editImg}
                                            />
                                            <span>編集</span>
                                        </Space>
                                    </Button>
                                </Space>
                                <Row gutter={[16, 10]}>
                                    <Col>
                                        <span>性別 : {patientDetail?.gender != undefined && GENDER_TEXT[patientDetail?.gender]}</span>
                                    </Col>
                                    <Col>
                                        <span>生年月日：{patientDetail?.birth && formatDateString(formatDate(patientDetail?.birth, '/'))}</span>
                                    </Col>
                                    <Col>
                                        <span>年齢 ： {patientDetail?.birth && getAge(patientDetail?.birth)}</span>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Space size={'large'}>
                                                <Space>
                                                    <span>体重 : </span>
                                                    <span>{patientDetail?.weight} kg</span>
                                                </Space>
                                                <Space>
                                                    <span>身長 : </span>
                                                    <span>{patientDetail?.height} cm</span>
                                                </Space>
                                                <Space>
                                                    <span>BMI : </span>
                                                    <span>{(patientDetail?.weight / (patientDetail?.height / 100) ** 2).toFixed(1) || ' '}</span>
                                                </Space>
                                            </Space>
                                        </Row>
                                    </Col>
                                    <Col span={24} style={{ paddingRight: '40px' }}>
                                        <Row>
                                            <Space>
                                                <span>備考 :</span>
                                                <span>{patientDetail?.comment}</span>
                                            </Space>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24} sm={24} md={24} lg={24} xl={14}>
                <Row>
                    <Col span={24}>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Space size={'large'}>
                                <span>今日の空腹時血糖値：<span style={{ color: '#0298DC' }}>{hungryGlucose ? hungryGlucose : '_____'} mg/dL</span></span>
                                <span>目標の空腹時血糖値：<span style={{ color: '#DC4602' }}>{patientDetail?.K ? patientDetail?.K : '_____'} mg/dL</span></span>
                            </Space>
                        </Row>
                    </Col>
                    <Col span={24} style={{ paddingTop: '15px' }}>
                        <Row>
                            {slidePoint != null && SLIDER_LENGTH == 9 && [0, 1, 2].map((i) => {
                                return <Col span={8}>
                                    <Row>
                                        {sliderArray.slice(i * 3, i * 3 + 3).map((item) => {
                                            const create_at = new Date(patientDetail?.started_at);
                                            create_at?.setDate(create_at?.getDate() + 7 * item)
                                            const color = item === sliderArray[0] ? '#293C4F' : item === sliderArray[sliderArray.length - 1] ? '#DC4602' : '#0298DC';
                                            return <Col span={8}>
                                                {(item === slidePoint || item === 0 || item === sliderArray.length - 1) &&
                                                    <Row className='center'>
                                                        <Col span={24}>
                                                            <div className='patient-slider-date' style={{ backgroundColor: color }}>
                                                                <p style={{ lineHeight: '1px' }}>
                                                                    <small>
                                                                        {item === 0 ? '開始日' : item === sliderArray.length - 1 ? '終了日' : '今日'}
                                                                    </small>
                                                                </p>
                                                                <p style={{ lineHeight: '1px', marginBlockEnd: '0px' }}>
                                                                    <small>
                                                                        <>{formatDateString(formatDate(create_at)).slice(5)}</>
                                                                    </small>
                                                                </p>
                                                            </div>
                                                            <div style={{ transform: 'translate(45%, 0)' }}>
                                                                <div className='patient-tri-shape' style={{ borderTop: '8px solid ' + color }} />
                                                            </div>
                                                        </Col>
                                                        <Col span={24} style={{ color: '#000000' }}>
                                                            <b><small>推定HbA1c</small></b>
                                                        </Col>
                                                        <Col span={24}>
                                                            <span className='patient-tags' style={{ color: color, }}>
                                                                {hba1c ?
                                                                    (item === sliderArray[0] ?
                                                                        parseFloat(hba1c['start']).toFixed(2)
                                                                        :
                                                                        item === sliderArray[sliderArray.length - 1] ? parseFloat(hba1c['expected']).toFixed(2) : parseFloat(hba1c['current']).toFixed(2))
                                                                    : '___'
                                                                }
                                                                %
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        })}
                                    </Row>
                                </Col>
                            }
                            )}
                        </Row>
                    </Col>

                    <Col span={24}>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={22} offset={1}>
                                <Slider marks={marks} value={slidePoint} max={SLIDER_LENGTH - 1} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col >
        </Row >
    )
}

export default PatientDetail;