import { Col, Row, UploadProps, Button, Upload, Space, message, Spin } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import React, { useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import '../index.css'
import { upload } from '../../../services/File/api';

const UploadCSV: React.FC = () => {

    const [files, setFiles] = useState<any>({});
    let constFiles = {};

    const action = async (file: any) => {
        constFiles = { ...constFiles, [file.file?.name]: [true, false] }
        setFiles(constFiles);
        const res = await upload({ 'files': file?.file });
        if (res.status) {
            constFiles = { ...constFiles, [file.file?.name]: [false, true] }
            setFiles(constFiles);
            message.success(res.message + res.data);
        }
        else {
            constFiles = { ...constFiles, [file.file?.name]: [false, false] }
            setFiles(constFiles);
            message.error(res.message + res?.data);
        }
        return res?.message;
    }

    const props: UploadProps = {
        name: 'files',
        accept: '.csv',
        multiple: true,
        showUploadList: false,
        customRequest: action,
    };

    return (
        <div className='csv-swapper'>
            <Row>
                <Col className='csv-col' md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }}>
                    <Dragger {...props}>
                        <p className="ant-upload-text">CSVファイルをドラック&ドロップ <br /> または <br /></p>
                        <p className="ant-upload-drag-icon" style={{ color: '#0298DC', textDecoration: 'underline' }}>
                            このテキストをクリックしてファイルを選択
                        </p>
                    </Dragger>
                    <Upload
                        {...props}
                        className="upload-list-inline"
                    >
                        <Button className='csv-submit' type='primary' >
                            <Space size={'large'}>
                                <img alt='' src='/assets/icons/upload.svg' style={{ paddingRight: '20px' }} />
                                <span>UPLOAD</span>
                            </Space>
                        </Button>
                    </Upload>
                </Col>
                <Col span={8} offset={8} style={{ marginTop: '80px', textAlign: 'center' }}>
                    {Object.keys(files).map(key => <Space>
                        {files[key][0] && <Spin />}
                        {key}
                        {files[key][1] ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}
                    </Space>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default UploadCSV;