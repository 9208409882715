import { Button, Col, Row, Space } from 'antd';
import { FileTextOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'

import React, { useState } from 'react';
import '../index.css';
import { useParams } from 'react-router-dom';
import HighChart from '../../../components/Graph';
import moment from 'moment';



const Summary: React.FC = () => {

    let params = useParams();
    const [diagramDate, setDiagramDate] = useState<string>(moment().subtract(1, 'week').format('YYYY-MM-DD'));
    const [loadingDiagram, setLoadingDiagram] = useState<boolean>(false);

    return (
        <>
            <Row style={{ paddingBottom: '20px', alignItems: 'center' }}>
                <Col>
                    <Space>
                        <FileTextOutlined /> <span className='patient-tags'>Summary</span>
                        <Button shape='circle' size='small' disabled={loadingDiagram}>
                            <LeftOutlined onClick={() => setDiagramDate(moment(diagramDate).subtract(1, 'week').format('YYYY-MM-DD'))} />
                        </Button>
                        <div style={{ backgroundColor: '#FFFFFF', padding: '2px 10px', borderRadius: '5px' }}>
                            <Space>
                                {moment(diagramDate).format('YYYY年MM月DD日')}
                                〜
                                {moment(diagramDate).add(1, 'week').format('YYYY年MM月DD日')}
                            </Space>
                        </div>
                        <Button
                            shape='circle'
                            size='small'
                            disabled={loadingDiagram}
                            hidden={diagramDate === moment().subtract(1, 'week').format('YYYY-MM-DD')}
                        >
                            <RightOutlined onClick={() => setDiagramDate(moment(diagramDate).add(1, 'week').format('YYYY-MM-DD'))} />
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row style={{ backgroundColor: '#FFFFFF', borderRadius: '16px', padding: '10px' }}>
                <Col style={{ width: '100%', fontWeight: '500' }}>
                    <HighChart
                        pa_id={params.id || ''}
                        start_date={diagramDate}
                        days={7}
                        setLoadingDiagram={setLoadingDiagram}
                    />
                </Col>
            </Row></>
    )
}

export default Summary;