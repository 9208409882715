import { Button, Col, Form, Input, message, Modal, Row, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import '../index.css';

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createSuggestedInsulin } from '../../../services/Insulin/api';
import { AppContext } from '../../../App';
import { ROLE } from '../../../utils/constant';


const EditInsulin: React.FC<{ insulin: any, fetchInsulin: any }> = (props) => {

    const [form] = Form.useForm();
    const { user } = useContext(AppContext)
    let params = useParams();

    const insulinName = ['fixed_dose', 'breakfast_dose', 'lunch_dose', 'dinner_dose'];
    const [insulin, setInsulin] = useState<number[]>(Object.keys(props?.insulin).length !== 0 ? insulinName.map(name => props?.insulin[name]) : [0, 0, 0, 0]);
    const [newInsulin, setNewInsulin] = useState<any>(Object.keys(props?.insulin).length !== 0 ? insulinName.map(name => props?.insulin[name]) : [0, 0, 0, 0]);
    const [suggestedInsulin, setSuggestedInsulin] = useState<API.CreateSuggestedInsulinRequest>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSend, setIsSend] = useState<boolean>(false);

    useEffect(() => {
        if (Object.keys(props?.insulin).length !== 0) {
            form.setFieldsValue(props?.insulin);
            setInsulin(insulinName?.map(name => props?.insulin[name]));
            setNewInsulin(insulinName?.map(name => props?.insulin[name]));
        }
    }, [props])

    const addInsulin = (index: number) => {
        newInsulin[index]++;
        setNewInsulin([...newInsulin]);
    }

    const minusInsulin = (index: number) => {
        if (newInsulin[index] !== 0) {
            newInsulin[index]--;
            setNewInsulin([...newInsulin])
        }
    }

    const onFinish = async (values: any) => {
        setModalVisible(!modalVisible)
        values['pa_id'] = params.id
        insulinName.map((name: string, index: number) => values[name] = newInsulin[index]);
        setSuggestedInsulin(values)
    }

    const confirmSuggestedInsulin = async () => {
        setLoading(true);
        if (suggestedInsulin) {
            const res = await createSuggestedInsulin(suggestedInsulin);
            if (res.status) {
                setIsSend(true);
            }
            else {
                setLoading(false);
                let error = '';
                if (res?.errors)
                    Object.keys(res?.errors)?.forEach(element => {
                        error += (res?.errors[element] && res?.errors[element][0]) ? res?.errors[element][0] : ''
                    })
                else error = res?.message;
                message.error(error);
            }
        }
    }

    const cancelConfirm = () => {
        setModalVisible(false);
        setLoading(false);
        setIsSend(false);
        props?.fetchInsulin();
    }

    return (
        <Row>
            <Col span={24}>
                <Space>
                    <img alt='' src={'/assets/icons/insulin.svg'} />
                    <span className='patient-tags'>Insulin</span>
                </Space>
            </Col>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Row gutter={100}>
                        <Col span={9}>
                            <Row gutter={[0, 10]}>
                                <Col span={24}>
                                    持効型（眠前)
                                </Col>
                                <Col span={24}>
                                    <Form.Item name='fixed_dose_name'>
                                        <Input
                                            prefix={<img alt='' src='/assets/icons/pen.svg' />}
                                            size={'large'}
                                            placeholder='インスリン名'
                                            disabled={user.role !== ROLE.DOCTOR}
                                            style={{ borderRadius: '22px', width: '60%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    前回処方単位
                                </Col>
                                <Col span={24} className='patient-insulin'>
                                    <span style={{ fontSize: '25px' }}>{insulin[0]}</span>
                                </Col>
                                <Col span={24}>
                                    <span>新規処方単位</span>
                                </Col>
                                <Col span={24} className='patient-col' hidden={user.role !== ROLE.DOCTOR}>
                                    <Space size={'large'}>
                                        <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { minusInsulin(0) }}>
                                            <MinusOutlined />
                                        </Button>
                                        <span className='patient-insulin-dose'>{newInsulin[0]}</span>
                                        <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { addInsulin(0) }} >
                                            <PlusOutlined />
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={15}>
                            <Row gutter={20}>
                                <Col span={8}>
                                    <Row gutter={[0, 10]}>
                                        <Col span={24}>
                                            朝食
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name='breakfast_dose_name'>
                                                <Input
                                                    prefix={<img alt='' src='/assets/icons/pen.svg' />}
                                                    size={'large'}
                                                    placeholder="インスリン名"
                                                    disabled={user.role !== ROLE.DOCTOR}
                                                    className='patient-insulin-input'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            前回処方単位
                                        </Col>
                                        <Col span={24} className='patient-insulin'>
                                            <span style={{ fontSize: '25px  ' }}>{insulin[1]}</span>
                                        </Col>
                                        <Col span={24}>
                                            <span>新規処方単位</span>
                                        </Col>
                                        <Col span={24} className='patient-col' hidden={user.role !== ROLE.DOCTOR} >
                                            <Space size={'large'}>
                                                <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { minusInsulin(1) }}>
                                                    <MinusOutlined />
                                                </Button>
                                                <span className='patient-insulin-dose'>{newInsulin[1]}</span>
                                                <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { addInsulin(1) }} >
                                                    <PlusOutlined />
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <Row gutter={[0, 10]}>
                                        <Col span={24}>
                                            昼食
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name='lunch_dose_name'>
                                                <Input
                                                    prefix={<img alt='' src='/assets/icons/pen.svg' />}
                                                    size={'large'}
                                                    placeholder="インスリン名"
                                                    disabled={user.role !== ROLE.DOCTOR}
                                                    className='patient-insulin-input'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            前回処方単位
                                        </Col>
                                        <Col span={24} className='patient-insulin'>
                                            <span style={{ fontSize: '25px  ' }}>{insulin[2]}</span>
                                        </Col>
                                        <Col span={24}>
                                            <span>新規処方単位</span>
                                        </Col>
                                        <Col span={24} className='patient-col' hidden={user.role !== ROLE.DOCTOR}>
                                            <Space size={'large'}>
                                                <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { minusInsulin(2) }}>
                                                    <MinusOutlined />
                                                </Button>
                                                <span className='patient-insulin-dose'>{newInsulin[2]}</span>
                                                <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { addInsulin(2) }}>
                                                    <PlusOutlined />
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <Row gutter={[0, 10]}>
                                        <Col span={24}>
                                            夕食
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name='dinner_dose_name'>
                                                <Input
                                                    prefix={<img alt='' src='/assets/icons/pen.svg' />}
                                                    size={'large'}
                                                    placeholder="インスリン名"
                                                    disabled={user.role !== ROLE.DOCTOR}
                                                    className='patient-insulin-input'
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            前回処方単位
                                        </Col>
                                        <Col span={24} className='patient-insulin'>
                                            <span style={{ fontSize: '25px  ' }}>{insulin[3]}</span>
                                        </Col>
                                        <Col span={24}>
                                            <span>新規処方単位</span>
                                        </Col>
                                        <Col span={24} className='patient-col' hidden={user.role !== ROLE.DOCTOR}>
                                            <Space size={'large'}>
                                                <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { minusInsulin(3) }} >
                                                    <MinusOutlined />
                                                </Button>
                                                <span className='patient-insulin-dose'>{newInsulin[3]}</span>
                                                <Button shape='circle' size='middle' className='patient-insulin-button' onClick={() => { addInsulin(3) }} >
                                                    <PlusOutlined />
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ textAlign: 'center', padding: '40px' }}>
                    <Button
                        className='patient-record-button'
                        hidden={user.role !== ROLE.DOCTOR}
                        htmlType='submit' type='primary' size='large'>
                        インスリンの単位数を変更する
                    </Button>
                </Col>
            </Form>
            <Modal
                className='insulin-modal'
                visible={modalVisible}
                onCancel={cancelConfirm}
                footer={!isSend && [
                    <>
                        <Button key="back" onClick={cancelConfirm}>
                            キャンセル
                        </Button>
                        <Button
                            key="submit"
                            style={{ borderLeft: "1px solid #707070" }}
                            onClick={confirmSuggestedInsulin}
                            loading={loading}
                        >
                            確 定
                        </Button>
                    </ >
                ]}
            >
                <span>{isSend ? '送信しました' : '本当にこの内容で送信しますか？'}</span>
            </Modal >
        </Row >
    )
}

export default EditInsulin;