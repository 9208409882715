import { Button, Form, Modal, Select } from 'antd';
import '../index.css';

import React, { useEffect, useState } from 'react';
import { getDoctors } from '../../../services/Doctor/api';


const AssginmentModal: React.FC<{ doctor: any, visible: boolean, handleVisible: any, setShowAssign: any, setDoctorID: any }> = (props) => {

    const [form] = Form.useForm();
    const [doctors, setDoctors] = useState<API.Doctor[]>([]);

    const fetchDoctors = async () => {
        setDoctors(await getDoctors());
    }

    useEffect(() => {
        fetchDoctors();
    }, [props])

    const onFinish = () => {
        if(form.getFieldsValue().doctor) {
            props?.setShowAssign(true);
            props?.handleVisible(false);
            props?.setDoctorID(form.getFieldsValue().doctor);
        }
        console.log('values', form.getFieldsValue());
    }

    return (
        <Modal
            className='insulin-modal'
            visible={props?.visible}
            onCancel={() => props?.handleVisible(false)}
            onOk={form.submit}
            footer={[
                <>
                    <Button key="back" onClick={() => props?.handleVisible(false)}>
                        back
                    </Button>
                    <Button
                        key="submit"
                        form="myForm"
                        htmlType="submit"
                        style={{ borderLeft: "1px solid #707070" }}
                        onClick={onFinish}
                    // loading={loading}
                    >
                        confirm
                    </Button>
                </ >
            ]}
        >
            <Form
                id='myForm'
                name="basic"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    label="Doctor"
                    name="doctor"
                    rules={[{ required: true, message: 'Please select the doctor!' }]}
                >
                    <Select
                        placeholder='Select your doctor you want to assign'
                        options={
                            doctors && doctors.length > 0 ? doctors?.map((doctor: API.Doctor) => {
                                return {
                                    label: doctor.name,
                                    value: doctor.id,
                                }
                            }) : []
                        }
                    />
                </Form.Item>
            </Form>
        </Modal >
    )
}

export default AssginmentModal;