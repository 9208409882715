import { Button, Col, message, Row, Space, Spin } from 'antd';

import React, { useContext, useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import '../index.css';
import { formatDateString } from '../../../utils/helper';
import { addNote, getNote } from '../../../services/Note/api';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";
import { AppContext } from '../../../App';
import { ROLE } from '../../../utils/constant';


const RecordAndNote: React.FC<{ insulin: API.SuggestedInsulinResponse[] }> = (props) => {

    const param = useParams();
    const { user } = useContext(AppContext);
    const [note, setNote] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const handleSaveNote = async () => {
        setLoading(true);
        if (note?.trim().length > 0 && param.id) {
            try {
                const res = await addNote({ 'pa_id': param.id, 'note': note });
                if (res.status) {
                    setNote("");
                    setLoading(false);
                    message.success(res.message);
                    await fetchData();
                } else {
                    let error = '';
                    if (res?.errors)
                        Object.keys(res?.errors)?.forEach(element => {
                            error += (res?.errors[element] && res?.errors[element][0]) ? res?.errors[element][0] : ''
                        })
                    else error = res?.message;
                    setLoading(false);
                    message.error(error);
                }
            } catch (err) {
            }
        } else {
            setLoading(false);
        }
    };
    const spinicon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const params = useParams();
    const [noteHistory, setNoteHistory] = useState<API.Note[]>();

    const fetchData = async () => {
        const data = await getNote({ pa_id: params.id || ' ' });
        if (data) {
            setNoteHistory(data);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <Row gutter={30}>
            <Col span={12}>
                <Space style={{ paddingBottom: '20px' }}>
                    <img alt='' src={'/assets/icons/clipboard.svg'} />
                    <span className='patient-tags'>Record</span>
                </Space>
                {props && props.insulin && props.insulin.length > 0 &&
                    props.insulin.map((item) =>
                        <Row>
                            <div className='insulin-history'>
                                <div>インスリン変更日：{formatDateString(item.date, '-')} {item.time} <br /></div>
                                <span>朝　：{item.breakfast_dose_name}　{item.breakfast_dose}単位</span><br />
                                <span>昼　：{item.lunch_dose_name}　{item.lunch_dose}単位</span><br />
                                <span>夕　：{item.dinner_dose_name}　{item.dinner_dose}単位</span><br />
                                持効型：{item.fixed_dose_name}　　{item.fixed_dose}単位
                            </div>
                        </Row>
                    )}
            </Col>
            <Col span={12}>
                <Space style={{ paddingBottom: '20px' }}>
                    <img alt='' src={'/assets/icons/clipboard.svg'} />
                    <span className='patient-tags'>Note</span>
                </Space>
                <Button
                    className='button-note'
                    loading={loading}
                    onClick={handleSaveNote}
                >
                    保存
                </Button>
                <Row>
                    {user.role !== ROLE.PHAGE &&
                        <div style={{ backgroundColor: '#FFFFFF', width: '100%', borderRadius: '10px', padding: '10px 20px' }}>
                            <Spin spinning={loading} indicator={spinicon}>
                                <TextArea
                                    style={{ border: "none" }}
                                    placeholder="テキストを入力できます。"
                                    value={note}
                                    disabled={user.role !== ROLE.DOCTOR}
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Spin>

                            {noteHistory && noteHistory.length > 0 && noteHistory.map(item => <div style={{ fontWeight: 'normal' }}>
                                <b>{formatDateString(item?.date || '', '-')?.slice(5)}</b>
                                <br />
                                <span style={{ whiteSpace: "pre-line" }}>
                                    {item.note}
                                </span>
                                <br /><br />
                            </div>)}
                        </div>}
                </Row>
            </Col>
        </Row>
    )
}

export default RecordAndNote;