import axios from "axios";
import { headers } from "../../utils/constant";

export async function addPatient(body: API.PatientParams) {
    var response: any; 
    await axios({
        method: 'POST',
        url: '/admin/patient',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}


export async function editPatient(body: API.PatientParams) {
    var response: any; 
    await axios({
        method: 'PUT',
        url: '/admin/patient',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}


export async function getPatientById(body: API.GetPatientByIdParams) {
    var response: any; 
    await axios({
        method: 'GET',
        url: '/admin/patient/' + body.pa_id,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data?.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}

export async function getPatients(body: API.GetPatientsParams) {
    var response: any; 
    await axios({
        method: 'GET',
        url: '/admin/patient',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data?.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}


export async function deletePatient(body: API.DeletePatient) {
    var response: any; 
    await axios({
        method: 'POST',
        url: '/admin/patient/delete',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}


export async function getPatientsByDoctorId(body: API.getPatientsByDoctorIdParams) {
    var response: any; 
    await axios({
        method: 'GET',
        url: '/admin/doctor/patient',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data?.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}