import JSZip from 'jszip';
import moment from 'moment';

export async function processCSV(data) {
    var zip = new JSZip();
    let archive = zip.folder('gf');

    if (data) {
        Object.keys(data).map(record => {
            if (data[record]['glucose'] && data[record]['glucose'].length > 0) {
                let glucose = data[record]['glucose'];
                glucose = Object.values(glucose).map((g: any) => {
                    return Object.values(g)
                })
                const meal = data[record]['meal'];
                let result: any[] = [['患者ID', 'タイムスタンプ', 'glucose値 mg/dL', '低血糖', '高血糖', '種類', 'ミールスコア', '写真有無', '食事名', 'insulin単位', 'insulin名']];
                if (meal && meal.length > 0) {
                    var index = 0;
                    glucose.forEach((g: any, i: number) => {
                        if (g[1].split(' ')[0] == meal[index]['date']) {
                            var gNext = glucose[i + 1] || null;
                            if (gNext && (moment(gNext[1]).format('YYYY-MM-DD') != moment(meal[index]['date']).format('YYYY-MM-DD'))) {
                                if (meal[index]['breakfast_time']) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['breakfast_time'], , , ,
                                            'breakfast_time',
                                            meal[index]['breakfast_score'],
                                            meal[index]['breakfast_img'] ? 1 : 0,
                                            meal[index]['breakfast'],
                                            (insulin && insulin['breakfast_dose_status'] == 1) ? insulin['breakfast_dose'] : null,
                                            (insulin && insulin['breakfast_dose_status'] == 1) ? insulin['breakfast_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['breakfast_time'] = null;
                                }
                                else if (meal[index]['lunch_time']) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['lunch_time'], , , ,
                                            'lunch_time',
                                            meal[index]['lunch_score'],
                                            meal[index]['lunch_img'] ? 1 : 0,
                                            meal[index]['lunch'],
                                            (insulin && insulin['lunch_dose_status'] == 1) ? insulin['lunch_dose'] : null,
                                            (insulin && insulin['lunch_dose_status'] == 1) ? insulin['lunch_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['lunch_time'] = null;

                                }
                                else if (meal[index]['dinner_time']) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['dinner_time'], , , ,
                                            'dinner_time',
                                            meal[index]['dinner_score'],
                                            meal[index]['dinner_img'] ? 1 : 0,
                                            meal[index]['dinner'],
                                            (insulin && insulin['dinner_dose_status'] == 1) ? insulin['dinner_dose'] : null,
                                            (insulin && insulin['dinner_dose_status'] == 1) ? insulin['dinner_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['dinner_time'] = null;
                                }
                                index += (meal.length - 1) > index ? 1 : 0;
                            }
                            else {
                                if (meal[index]['breakfast_time'] && moment(g[1]) > (moment(meal[index]['date'] + ' ' + meal[index]['breakfast_time']))) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['breakfast_time'], , , ,
                                            'breakfast_time',
                                            meal[index]['breakfast_score'],
                                            meal[index]['breakfast_img'] ? 1 : 0,
                                            meal[index]['breakfast'],
                                            (insulin && insulin['breakfast_dose_status'] == 1) ? insulin['breakfast_dose'] : null,
                                            (insulin && insulin['breakfast_dose_status'] == 1) ? insulin['breakfast_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['breakfast_time'] = null;
                                }
                                else if (meal[index]['lunch_time'] && moment(g[1]) > (moment(meal[index]['date'] + ' ' + meal[index]['lunch_time']))) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['lunch_time'], , , ,
                                            'lunch_time',
                                            meal[index]['lunch_score'],
                                            meal[index]['lunch_img'] ? 1 : 0,
                                            meal[index]['lunch'],
                                            (insulin && insulin['lunch_dose_status'] == 1) ? insulin['lunch_dose'] : null,
                                            (insulin && insulin['lunch_dose_status'] == 1) ? insulin['lunch_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['lunch_time'] = null;

                                }
                                else if (meal[index]['dinner_time'] && moment(g[1]) > (moment(meal[index]['date'] + ' ' + meal[index]['dinner_time']))) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['dinner_time'], , , ,
                                            'dinner_time',
                                            meal[index]['dinner_score'],
                                            meal[index]['dinner_img'] ? 1 : 0,
                                            meal[index]['dinner'],
                                            (insulin && insulin['dinner_dose_status'] == 1) ? insulin['dinner_dose'] : null,
                                            (insulin && insulin['dinner_dose_status'] == 1) ? insulin['dinner_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['dinner_time'] = null;
                                }
                                if (!meal[index]['breakfast_time'] && !meal[index]['lunch_time'] && !meal[index]['dinner_time']) {
                                    index += (meal.length - 1) > index ? 1 : 0;
                                }
                            }
                        }
                        else {
                            if (moment(g[1].split(' ')[0]) > moment(meal[index]['date'])) {
                                if (meal[index]['breakfast_time']) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['breakfast_time'], , , ,
                                            'breakfast_time',
                                            meal[index]['breakfast_score'],
                                            meal[index]['breakfast_img'] ? 1 : 0,
                                            meal[index]['breakfast'],
                                            (insulin && insulin['breakfast_dose_status'] == 1) ? insulin['breakfast_dose'] : null,
                                            (insulin && insulin['breakfast_dose_status'] == 1) ? insulin['breakfast_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['breakfast_time'] = null;
                                }
                                else if (meal[index]['lunch_time']) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['lunch_time'], , , ,
                                            'lunch_time',
                                            meal[index]['lunch_score'],
                                            meal[index]['lunch_img'] ? 1 : 0,
                                            meal[index]['lunch'],
                                            (insulin && insulin['lunch_dose_status'] == 1) ? insulin['lunch_dose'] : null,
                                            (insulin && insulin['lunch_dose_status'] == 1) ? insulin['lunch_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['lunch_time'] = null;

                                }
                                else if (meal[index]['dinner_time']) {
                                    const insulin = data[record]['insulin']?.find(item => item.date == meal[index]['date']);
                                    result.push(
                                        [
                                            record,
                                            meal[index]['date'] + ' ' + meal[index]['dinner_time'], , , ,
                                            'dinner_time',
                                            meal[index]['dinner_score'],
                                            meal[index]['dinner_img'] ? 1 : 0,
                                            meal[index]['dinner'],
                                            (insulin && insulin['dinner_dose_status'] == 1) ? insulin['dinner_dose'] : null,
                                            (insulin && insulin['dinner_dose_status'] == 1) ? insulin['dinner_dose_name'] : null,
                                        ]
                                    )
                                    meal[index]['dinner_time'] = null;
                                }
                                index += (meal.length - 1) > index ? 1 : 0;
                            }
                        }
                        result.push(g);
                    })
                } else {
                    result = glucose;
                }
                if (archive) {
                    const csvData = result.map(e => e.join(",")).join("\n");
                    archive.file(record + '_gf_' + moment().format('YYYYMMDD') + '.csv', csvData)
                }
            }
        })
        if (archive) {
            archive.generateAsync({
                type: "base64",
                compression: "DEFLATE",
            }).then(function (content) {
                window.location.href = "data:application/zip;base64," + content;
            });
        }
    }
}