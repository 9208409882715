import axios from "axios";
import { headers } from "../../utils/constant";

export async function getSuggestedInsulin(body: API.SuggestedInsulinRequest) {
    var response: any; 
    await axios({
        method: 'GET',
        url: 'admin/insulin/suggested',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    if(response?.status){
        response = response?.data;
    }
    else response = [];
    return response;
}

export async function createSuggestedInsulin(body: API.CreateSuggestedInsulinRequest) {
    var response: any; 
    await axios({
        method: 'POST',
        url: 'admin/insulin/suggested',
        params: body,
        headers: headers,
        responseType: 'json',
    }).then(res => {
        response = res.data;
    }).catch(res => {
        response = res.response.data;
    });
    return response;
}