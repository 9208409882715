import Home from "../pages/Home";
import NoFoundPage from '../pages/Error/404';
import UploadCSV from '../pages/Home/components/UploadCSV';
import Pantient from '../pages/Patient';
import Register from "../pages/User/Register";
import Login from "../pages/User/Login";
import ResetPassWord from "../pages/User/ResetPass";
import VideoCall from "../pages/User/VideoCall";

export const privateRoutes = [
    {
        path: 'home',
        whiteFooter: false,
        element: <Home />
    },
    {
        path: 'upload',
        whiteFooter: true,
        element: <UploadCSV />
    },
    {
        path: '/patient/:id',
        whiteFooter: false,
        element: <Pantient />
    },
    {
        path: '*',
        whiteFooter: false,
        element: <NoFoundPage />
    }
]

export const routes = [
    {
        path: 'login',
        element: <Login />
    },
    {
        path: 'register',
        element: <Register />
    },
    {
        path: "reset-password",
        element: <ResetPassWord />,
        key: "reset-pass",
    },
    {
        path: "reset-password/:token",
        element: <ResetPassWord />,
        key: "reset-pass",
    },
    {
        path: "aimesoft",
        element: <VideoCall />,
        key: "reset-pass",
    },
]