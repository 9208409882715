const Footer: React.FC<{ whiteFooter: boolean }> = (props) => {

    return (
        <div className="footer" style={{
            backgroundColor: props?.whiteFooter ?
                '#FFFFFF' : '#E2E7ED'
        }}>
            THE PHAGE, Inc.
        </div >
    );
};

export default Footer;