import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../App';
import { addPatient, deletePatient, editPatient, getPatientById } from '../../../services/Patient/api';
import { GENDER, GENDER_TEXT, ROLE } from '../../../utils/constant';
import '../index.css';


const EditPatient: React.FC<{ handleEditPatient: any }> = (props: any) => {

    let params = useParams();
    const { user } = useContext(AppContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const fetchData = async () => {
        if (params.id) {
            const data = await getPatientById({ 'pa_id': params.id });
            if (data['birth']) {
                data['birth'] = moment(data['birth']);
            }
            if (data['started_at']) {
                data['started_at'] = moment(data['started_at']);
            }
            form.setFieldsValue(data);
        }
    }

    const onSubmit = async (values: any) => {
        Object.keys(values)?.forEach(key => {
            values[key] = values[key] ? values[key] : null;
        })
        if (values['birth']) {
            values['birth'] = moment(values['birth']).format('YYYY-MM-DD');
        }
        if (values['started_at']) {
            values['started_at'] = moment(values['started_at']).format('YYYY-MM-DD');
        }
        if (params.id) {
            values['pa_id'] = params.id;
            setLoading(true);

            const res = await editPatient(values);
            if (res.status) {
                message.success(res?.message);
                setLoading(false);
                props.handleEditPatient(false);
            }
            else {
                let error = '';
                if (res?.errors)
                    Object.keys(res?.errors)?.forEach(element => {
                        error += (res?.errors[element] && res?.errors[element][0]) ? res?.errors[element][0] : ''
                    })
                else error = res?.message;
                message.error(error);
                setLoading(false);
            }
        }
        else {
            setLoading(true);
            const res = await addPatient(values);
            if (res.status) {
                message.success(res?.message);
                setLoading(false);
                props.handleEditPatient(false);
            }
            else {
                let error = '';
                if (res?.errors)
                    Object.keys(res?.errors)?.forEach(element => {
                        error += (res?.errors[element] && res?.errors[element][0]) ? res?.errors[element][0] : ''
                    })
                else error = res?.message;
                message.error(error);
                setLoading(false);
            }
        }
    }

    const handleDeleteAccount = async () => {
        setLoadingDelete(true);
        const res = await deletePatient({ pa_id: params.id || '' })
        if (res.status) {
            message.success(res?.message)
            setModalVisible(false);
            props.handleEditPatient(false);
            navigate('/home')
        }
        else {
            message.error(res?.error)
        }
        setLoadingDelete(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div>
            <div>
                <Row>
                    <Col span={12}>
                        <span><h1>患者情報の編集</h1></span>
                    </Col>
                    <Col span={12}>
                        <Button
                            type='primary'
                            danger
                            style={{ float: 'right', backgroundColor: '#DC4602', borderRadius: '10px' }}
                            hidden={user.role === ROLE.DOCTOR || !params.id}
                            onClick={() => setModalVisible(true)}
                        >
                            アカウント削除
                        </Button>
                    </Col>
                </Row>
            </div>
            <Form
                className='special-form'
                name="wrap"
                labelCol={{ flex: '200px' }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                onFinish={onSubmit}
                style={{ paddingBottom: '50px' }}
                form={form}
            >
                <Form.Item className='input-item' label={<span className='inputEven'>患者ID</span>} name="pa_id" >
                    <Input className='input-both' placeholder='テキストを入力してください' disabled={!!params.id && user.role === ROLE.DOCTOR} />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputEven'>名前</span>} name="name" hidden={!!params.id && user.role === ROLE.PHAGE} >
                    <Input className='input-both' placeholder='テキストを入力してください' />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputOdd'>名前（カナ）</span>} name="kana_name" hidden={!!params.id && user.role === ROLE.PHAGE} >
                    <Input className='input-both' placeholder='テキストを入力してください' />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputOdd'>性別</span>} name="gender" hidden={!!params.id && user.role === ROLE.PHAGE} >
                    <Select
                        placeholder='テキストを入力してください'
                        options={[
                            {
                                value: GENDER.UNKNOWN,
                                label: GENDER_TEXT[0],
                            },
                            {
                                value: GENDER.MALE,
                                label: GENDER_TEXT[1],
                            },
                            {
                                value: GENDER.FEMALE,
                                label: GENDER_TEXT[2],
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputEven'>生年月日</span>} name="birth" hidden={!!params.id && user.role === ROLE.PHAGE} >
                    <DatePicker
                        style={{ backgroundColor: 'transparent', width: '100%', border: 'none' }}
                        placeholder='テキストを入力してください'
                        format='YYYY/MM/DD'
                    />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputOdd'>身長 (cm)</span>} name="height" >
                    <Input className='input-both' placeholder='テキストを入力してください' />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputEven'>体重 (kg)</span>} name="weight" >
                    <Input className='input-both' placeholder='テキストを入力してください' />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputOdd' style={{ color: '#DC4602' }}>試験開始日（0週）</span>} name="started_at" >
                    <DatePicker
                        style={{ backgroundColor: 'transparent', width: '100%', border: 'none' }}
                        placeholder='テキストを入力してください'
                        format='YYYY/MM/DD'
                    />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputEven' style={{ color: '#DC4602' }}>目標空腹時血糖値</span>} name="K" >
                    <Input className='input-both' placeholder='テキストを入力してください' />
                </Form.Item>
                <Form.Item className='input-item' label={<span className='inputOdd'>備考（患者について）</span>} name="comment" >
                    <Input className='input-both' placeholder='テキストを入力してください' />
                </Form.Item>
                {(!params.id || user.role !== ROLE.PHAGE) &&
                    <Form.Item className='input-item' label={<span className='inputEven'>パスワード</span>} name="password" >
                        <Input className='input-both' placeholder='テキストを入力してください' />
                    </Form.Item>
                }
                <Row>
                    <Col span={6} offset={9}>
                        <Button
                            className='edit-patient-submit'
                            type='primary'
                            htmlType="submit"
                            size='large'
                            loading={loading}
                        >
                            患者情報を保存する
                        </Button>
                        <Button className='edit-patient-back' onClick={props.handleEditPatient}>キャンセル</Button>
                    </Col>
                </Row>
            </Form>
            <Modal
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={[
                    <>
                        <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                        <Button type='primary' danger loading={loadingDelete} onClick={handleDeleteAccount}>Confirm</Button>
                    </>
                ]}
            >
                Are you sure to delete this account?
            </Modal>
        </div>
    );
};

export default EditPatient;