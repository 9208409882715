export function formatDateString(dateString: string, s:string = '/') {
    const dateObj = dateString.split(s);
    if(dateObj.length < 3) return dateString;
    return dateObj[0] + '年' + dateObj[1] + '月' + dateObj[2] + '日';
}

export function formatDate(date: Date, s: string = '/') {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join(s);
}

export function formatTime(d: Date, s: string) {
        var hours = '' + d.getHours(),
        minutes = '' + d.getMinutes(),
        senconds = '' + d.getSeconds();

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (senconds.length < 2) senconds = '0' + senconds;

    return [hours, minutes, senconds].join(s);
}

export function getweeks(currentDate: Date, week = 1) {
    const startDate = new Date(currentDate);
    startDate.setDate( startDate.getDate() - week*7 )
    return [currentDate, startDate];
}

export function formatDateAndTime(date) {
    const formatted = date.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/)
    if(formatted.length>2) return formatted[1] + ' ' + formatted[2]
    return ''
}